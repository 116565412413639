import React from "react";
import domains from "../../../configuration/domains";
import Heading from "../../atoms/Heading";
import Page from "../../organisms/Page";
import Sidebar from "../../molecules/Sidebar";

import * as p from "../../organisms/Page/styles.module.scss";
import * as s from "./styles.module.scss";

const { baseUrl, imageCdnUrl } = domains;

export default function Publications() {
    return (
        <Page
            metaTitle="Publications"
            canonicalUrl={`${baseUrl}/publications`}
            metaDescription="Panagiotis Andreou's awards and published photographs."
            heroImages={[
                `${imageCdnUrl}/Banners/LEAM_MILL_2018_14MM_MOBILE_hgue1w.jpg`, // 768 Mobile
                `${imageCdnUrl}/Banners/LEAM_MILL_2018_14MM_TABLET_jg7mi4.jpg`, // 992 Tablet
                `${imageCdnUrl}/Banners/LEAM_MILL_2018_14MM_DESKTOP_glf8q7.jpg`, // 2880 Desktop
            ]}
            heroBgPosition="650px 0"
            isFullBanner={false}
        >
            <div className={p.containerWrapper}>
                <div className={p.container}>
                    <Heading level={1} size="large" className={p.heading}>
                        Awards and published photographs
                    </Heading>

                    <Heading level={2} size="xsmall" className={s.entryHeading}>
                        Sky & Telescope Magazine (US)
                    </Heading>
                    <ul className={s.ulEntry}>
                        <li>
                            <a
                                href="/deep-sky/north-america-and-pelican-nebulae-super-widefield-2021"
                                title="North America and Pelican Nebulae Super Widefield, 2021"
                            >
                                "North America and Pelican Nebulae Super
                                Widefield, 2021"
                            </a>{" "}
                            was featured in the image gallery of the magazine
                            (November 2021 issue).
                        </li>
                    </ul>
                    <p>
                        <img
                            src={`${imageCdnUrl}/Publications/SKY_AND_TELESCOPE_US_MAGAZINE_11_2021_CYGNUS_FSQ85ED_D810A_xpwm2q.jpg`}
                            title="Sky & Telescope, November 2021 Issue"
                            alt="Sky & Telescope, November 2021 Issuee"
                            className={s.image}
                        />
                    </p>

                    <Heading level={2} size="xsmall" className={s.entryHeading}>
                        Astronomy Now Magazine (UK)
                    </Heading>
                    <ul className={s.ulEntry}>
                        <li>
                            <a
                                href="/deep-sky/north-america-and-pelican-nebulae-super-widefield-2021"
                                title="North America and Pelican Nebulae Super Widefield, 2021"
                            >
                                "North America and Pelican Nebulae Super
                                Widefield, 2021"
                            </a>{" "}
                            was featured in the image gallery of the magazine
                            (Sepetmber 2021 issue).
                        </li>
                    </ul>
                    <p>
                        <img
                            src={`${imageCdnUrl}/Publications/ASTRONOMY_NOW_UK_MAGAZINE_07_2021_CYGNUS_FSQ85_D810A_ozcp0u.jpg`}
                            title="Astronomy Now, September 2021 Issue"
                            alt="Astronomy Now, September 2021 Issuee"
                            className={s.image}
                        />
                    </p>

                    <Heading level={2} size="xsmall" className={s.entryHeading}>
                        BBC - Sky At Night Magazine (UK)
                    </Heading>
                    <ul className={s.ulEntry}>
                        <li>
                            <a
                                href="/deep-sky/m42/orion-nebula-exmoor-2018"
                                title="Orion Nebula, Exmoor, 2018"
                            >
                                "Orion Nebula, Exmoor, 2018"
                            </a>{" "}
                            was featured in the image gallery of the magazine
                            (January 2019 issue).
                        </li>
                    </ul>
                    <p>
                        <img
                            src={`${imageCdnUrl}/Publications/Publication_Sky_At_Night_01_2019_Issue_680W_abmbjw.jpg`}
                            title="BBC - The Sky At Night, January 2019 Issue"
                            alt="BBC - The Sky At Night, January 2019 Issue"
                            className={s.image}
                        />
                    </p>

                    <Heading level={2} size="xsmall" className={s.entryHeading}>
                        EAPOD - European Astronomy Picture of the Day
                    </Heading>
                    <ul className={s.ulEntry}>
                        <li>
                            The Andromeda 2020 Exmoor image was featured as the
                            European astronomy picture of the day in the EAPOD
                            website.{" "}
                            <a
                                href="https://eapod.org/to-the-limit-beyond-panagiotis-andreou/"
                                title="To the limit and beyond - Panagiotis Andreou"
                                target="_blank"
                            >
                                <br />
                                To the Limit & Beyond | Panagiotis Andreou
                            </a>
                        </li>
                    </ul>

                    <Heading level={2} size="xsmall" className={s.entryHeading}>
                        The Webb Deep-Sky Society
                    </Heading>
                    <ul className={s.ulEntry}>
                        <li>
                            <a
                                href="/deep-sky/ic-1396/elephants-trunk-hoo-2019"
                                title="Elephant's Trunk Nebula, HOO"
                            >
                                "Elephant's Trunk Nebula, HOO"
                            </a>{" "}
                            was featured as Picture of the Month (September
                            2019) in{" "}
                            <a
                                href="https://www.webbdeepsky.com/picture-of-the-month/archive/2019"
                                title="The Webb Deep-Sky Society"
                            >
                                The Webb Deep-Sky Society
                            </a>{" "}
                            website.
                        </li>
                    </ul>

                    <Heading level={2} size="xsmall" className={s.entryHeading}>
                        Exmoor National Park
                    </Heading>
                    <ul className={s.ulEntry}>
                        <li>
                            <a
                                href="/starscape/sleeping-under-the-stars-exmoor-2020"
                                title="Sleeping under the stars, Exmoor 2020"
                            >
                                "Sleeping under the stars, Exmoor 2020"
                            </a>{" "}
                            won the Exmoor National Park's astrophotography
                            category 'Enjoying Exmoor dark skies' (2020
                            competition).
                        </li>
                        <li>
                            <a
                                href="deep-sky/m42/orion-nebula-exmoor-2018"
                                title="Orion Nebula, Exmoor, 2018"
                            >
                                "Orion Nebula, Exmoor, 2018"
                            </a>{" "}
                            is featured on Exmoor's National Park's{" "}
                            <a
                                href="https://www.exmoor-nationalpark.gov.uk/__data/assets/pdf_file/0029/383339/Astronomers-Guide-to-Exmoor-amend-May.pdf"
                                title="Exmoor National Park - Astronomers Guide to Exmoor"
                                target="_blank"
                            >
                                Astronomers' Guide to Exmoor
                            </a>
                            .
                        </li>
                    </ul>
                    <Heading level={2} size="xsmall" className={s.entryHeading}>
                        Flickr explore
                    </Heading>
                    <ul className={s.ulEntry}>
                        <li>
                            <a
                                href="https://www.flickr.com/explore/2023/08/04"
                                title="August 4, 2023 Flickr Explore"
                                target="_blank"
                            >
                                08-04-2023
                            </a>
                            :{" "}
                            <a
                                href="https://www.flickr.com/photos/157568575@N07/53092353408"
                                title="Butterfly and Ptolemy Cluster Hellas 2022"
                                target="_blank"
                            >
                                "Butterfly and Ptolemy Cluster Hellas 2022"
                            </a>
                        </li>

                        <li>
                            <a
                                href="https://www.flickr.com/explore/2021/05/08"
                                title="October 15, 2020 Flickr Explore"
                                target="_blank"
                            >
                                08-05-2021
                            </a>
                            :{" "}
                            <a
                                href="https://www.flickr.com/photos/157568575@N07/51165508161"
                                title="Our closest cosmic neighbour, Andromeda"
                                target="_blank"
                            >
                                "Our closest cosmic neighbour, Andromeda"
                            </a>
                        </li>

                        <li>
                            <a
                                href="https://www.flickr.com/explore/2020/10/15"
                                title="October 15, 2020 Flickr Explore"
                                target="_blank"
                            >
                                15-10-2020
                            </a>
                            :{" "}
                            <a
                                href="https://www.flickr.com/photos/157568575@N07/50484138357"
                                title="IC 1396, Exmoor, 2020"
                                target="_blank"
                            >
                                "IC 1396, Exmoor, 2020"
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.flickr.com/explore/2020/07/12"
                                title="July 12, 2020 Flickr Explore"
                                target="_blank"
                            >
                                12-07-2020
                            </a>
                            :{" "}
                            <a
                                href="https://www.flickr.com/photos/157568575@N07/50101490243"
                                title="Comet Neowise, 2020"
                                target="_blank"
                            >
                                "Comet Neowise, 2020"
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.flickr.com/explore/2020/05/28"
                                title="May 28, 2020 Flickr Explore"
                                target="_blank"
                            >
                                28-05-2020
                            </a>
                            :{" "}
                            <a
                                href="https://www.flickr.com/photos/157568575@N07/49943452036"
                                title="NGC 2237 Rosette Nebula SHO 2019"
                                target="_blank"
                            >
                                "NGC 2237 Rosette Nebula SHO 2019"
                            </a>
                        </li>
                    </ul>
                </div>
                <Sidebar />
            </div>
        </Page>
    );
}
